export const environment = {
    production: false,
  
    clientName: 'ashe',
    apiGatewayFront: 'https://agr.ashe.com.co/',
    apiGatewayBackOffice: 'https://agr.ashe.com.co/',
    apiUrl: 'https://agr.ashe.com.co/',
    urlPath: 'https://agr.ashe.com.co/',
    customCss: false,
    applicationType: "agr",
    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    enableDebug: false,
    // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
    // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX
  
    siteKeyCaptcha: '6LdNIjskAAAAAEOL8MZiKnZCvoZKGZgv40c9pD_3',
  
    googleAnalyticsCode: 'UA-140785247-1',
  };
  
  
  const environment2 = {
    production: false,
    clientName: 'ashe',
    apiGatewayFront: 'https://agr.ashe.com.co/',
    apiGatewayBackOffice: 'https://agr.ashe.com.co/',
    apiUrl: 'https://agr.ashe.com.co/',
    urlPath: 'https://agr.ashe.com.co/',
    customCss: false,
    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    enableDebug: false,
    // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
    // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX
    siteKeyCaptcha: '6LdNIjskAAAAAEOL8MZiKnZCvoZKGZgv40c9pD_3',
    googleAnalyticsCode: 'UA-140785247-1',
  };